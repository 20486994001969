<template>
  <div>
    <!-- banner -->
    <div class="banner" :style="cover">
      <h1 class="banner-title">个人收藏</h1>
    </div>
    <!-- 收藏列表 -->
    <div class="article-list-wrapper">
      <el-empty v-if="favoriteList.length === 0" description="暂无数据..."></el-empty>
      <v-row>
        <v-col md="3" cols="12" v-for="item of favoriteList" :key="item.id">

          <!-- 分类 -->
          <v-card class="animated zoomIn article-item-card">
            <div class="article-item-cover">
              <router-link :to="'/articles/' + item.articleId" tag="a" target="_blank">
                <!-- 缩略图 -->
                <v-img class="on-hover" width="100%" height="100%" :src="item.cover"/>
              </router-link>
            </div>
            <div class="article-item-info">
              <!-- 文章标题 -->
              <div>
                <router-link :to="'/articles/' + item.articleId" tag="a" target="_blank">
                  <strong><div class="showInline">{{ item.title }}</div></strong>
                </router-link>
                <el-button v-if="showCancelFavorite" type="text" size="small" style="float: right">取消收藏</el-button>
              </div>
              <div style="margin-top:0.375rem">
                <!-- 关注时间 -->
                <v-icon size="20">mdi-clock-outline</v-icon>
                创建时间：{{ item.createTime | date }}
              </div>

            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- 分页按钮 -->
      <v-pagination
          color="#00C4B6"
          v-model="current"
          :length="Math.ceil(count / param.pageSize)"
          total-visible="7"
      />

    </div>
  </div>
</template>

<script>
import { userinfo } from "@/api/user";
import { getFavoriteArticleByUserId } from "@/api/article";
import { getUserId } from "@/utils/cookieUtil";

export default {
  created() {
    if (getUserId()) {
      this.showCancelFavorite = true;
    }
    this.fetchFavoriteList();
    this.fetchUserInfo();
  },
  data: function() {
    return {
      count: 0,
      param: {
        pageNo: 1,
        pageSize: 8,
        userId: "",
      },
      moreTitle: "加载更多",
      jiazailoading: false,
      pageNo: 2,
      favoriteList: [],
      current: 1,
      userHomepageCover: "",
      showCancelFavorite: false
    }
  },
  methods: {
    // 获取用户粉丝列表
    fetchFavoriteList() {
      this.param.pageNo = this.current;
      this.param.userId = this.$route.params.userId
      getFavoriteArticleByUserId(this.param).then(res => {
        if (res.code === 200) {
          this.favoriteList = res.data.list;
          this.count = res.data.total
        }
      })
    },

    // 获取用户信息
    fetchUserInfo() {
      userinfo(this.$route.params.userId).then(res => {
        if (res.code === 200) {}
        this.userHomepageCover = res.data.homepageCover;
      })
    },
  },
  computed: {
    cover() {
      return "background: url(" + this.userHomepageCover + ") center center / cover no-repeat";
    }
  },

  watch: {
    current(value) {
      this.current = value;
      this.fetchFavoriteList();
    }
  }

};
</script>

<style scoped>

@media (min-width: 760px) {
  .article-list-wrapper {
    max-width: 1200px;
    margin: 370px auto 1rem auto;
  }
  .article-item-card:hover {
    transition: all 0.3s;
    box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  }
  .article-item-card:not(:hover) {
    transition: all 0.3s;
  }
  .article-item-card:hover .on-hover {
    transition: all 0.6s;
    transform: scale(1.1);
  }
  .article-item-card:not(:hover) .on-hover {
    transition: all 0.6s;
  }
  .article-item-info {
    line-height: 1.7;
    padding: 15px 15px 12px 18px;
    font-size: 15px;
  }
}
@media (max-width: 759px) {
  .article-list-wrapper {
    margin-top: 230px;
    padding: 0 12px;
  }
  .article-item-info {
    line-height: 1.7;
    padding: 15px 15px 12px 18px;
  }
}
.article-item-card {
  border-radius: 8px !important;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
}
.article-item-card a {
  transition: all 0.3s;
}
.article-item-cover {
  height: 220px;
  overflow: hidden;
}
.article-item-card a:hover {
  color: #8e8cd8;
}
.tag-wrapper {
  padding: 10px 15px 10px 18px;
}
.tag-wrapper a {
  color: #fff !important;
}
.tag-btn {
  display: inline-block;
  font-size: 0.725rem;
  line-height: 22px;
  height: 22px;
  border-radius: 10px;
  padding: 0 12px !important;
  background: linear-gradient(to right, #bf4643 0%, #6c9d8f 100%);
  opacity: 0.6;
  margin-right: 0.5rem;
}
.showInline {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
</style>
